/**
 * prism.js tomorrow night eighties for JavaScript, CoffeeScript, CSS and HTML
 * Based on https://github.com/chriskempson/tomorrow-theme
 * @author Rose Pritchard
 */

pre {
  background-color: rgb(40, 44, 52);
  padding: 15px;
  border-radius: 9px;
}

code[class*="lang-"],
pre[class*="lang-"] {
	color: #ccc;
	background: none;
	font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
	font-size: 1em;
	text-align: left;
	white-space: pre;
	word-spacing: normal;
	word-break: normal;
	word-wrap: normal;
	line-height: 1.5;

	-moz-tab-size: 4;
	-o-tab-size: 4;
	tab-size: 4;

	-webkit-hyphens: none;
	-moz-hyphens: none;
	-ms-hyphens: none;
	hyphens: none;

}

/* Code blocks */
pre[class*="lang-"] {
	padding: 1em;
	margin: .5em 0;
	overflow: auto;
}

:not(pre) > code[class*="lang-"],
pre[class*="lang-"] {
	background: #2d2d2d;
}

/* Inline code */
:not(pre) > code[class*="lang-"] {
	padding: .1em;
	border-radius: .3em;
	white-space: normal;
}

.cm-comment,
.cm-block-comment,
.cm-prolog,
.cm-doctype,
.cm-cdata {
	color: #999;
}

.cm-punctuation {
	color: #ccc;
}

.cm-tag,
.cm-attr-name,
.cm-namespace,
.cm-deleted {
	color: #e2777a;
}

.cm-function-name {
	color: #6196cc;
}

.cm-boolean,
.cm-number,
.cm-function {
	color: #f08d49;
}

.cm-property,
.cm-class-name,
.cm-constant,
.cm-symbol {
	color: #f8c555;
}

.cm-selector,
.cm-important,
.cm-atrule,
.cm-keyword,
.cm-builtin {
	color: #cc99cd;
}

.cm-string,
.cm-char,
.cm-attr-value,
.cm-regex,
.cm-variable {
	color: #7ec699;
}

.cm-operator,
.cm-entity,
.cm-url {
	color: #67cdcc;
}

.cm-important,
.cm-bold {
	font-weight: bold;
}
.cm-italic {
	font-style: italic;
}

.cm-entity {
	cursor: help;
}

.cm-inserted {
	color: green;
}
