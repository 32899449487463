.hero-container {
  /*background-color: #00299E;*/
  /*background-color: #F2F2F2;*/
  /*border-bottom: 1px solid #2E2E2E;*/
}

.hero-social-icons > *:not(:last-child) {
  margin-right: 15px;
}

.hero-social-icon {
  cursor: pointer;
  /*color: #FFFFFF;*/
  color: #2E2E2E;
}

.svg-row {
  width: 100%;
}

.hero-navbar {
  width: 100%;
}
