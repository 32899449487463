.error-text-container {
  position: relative;
}

.error-text {
  position: absolute;
}

@media (min-width: 768px) {
  .textfield-large {
    width: max(25vw, 300px);
  }

  .textfield {
    padding-left: 1vw;
  }
}

@media (max-width: 767px) {
  .textfield-large {
    width: 80vw;
  }

  .textfield {
    padding-left: 2vw;
  }
}

.textfield {
  border-radius: 9px;
  min-height: 45px;
  height: 3.5vw;
  border: 1px solid gray;
}
