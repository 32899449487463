
rect { fill: #FE0304; }
text {
	font-family: 'Montserrat', sans-serif;
	font-weight: 900;
	text-transform: uppercase;
	letter-spacing: 21px;
	fill: black;
}

@media (min-width: 768px) {
	svg { width: 33%; }

  text {
    font-size: 1.7vw;
  }
}

@media (max-width: 767px) {
	svg { width: 100%; }
  text {
    font-size: 6vw;
  }
}

@media (max-width: 767px) and (min-width: 576px) {
  text {
    font-size: 5vw;
  }
}

@media (max-width: 575px) {
  text {
    font-size: 6vw;
  }
}
