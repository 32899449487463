.App {
  text-align: center;
}

.app-container {
  /*background-image: linear-gradient(to bottom right, #F2F2F2, #F8F4C2 95%, #0F4499 110%);*/
  background-color: #F2F2F2;
  min-height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.formnoform-container {
  background-color: black;
  border-radius: 9px;
}

.somewheregood-container {
  background-image: linear-gradient(to bottom right, #3e3dd6 0% 50%, #ff6e00 100%);
  border-radius: 9px;
}

.ethels-container {
  background-image: linear-gradient(to bottom right, #ff3a0b 0% 70%, #f7ede3 100%);
  border-radius: 9px;
}

.restage-container {
  background-image: linear-gradient(to bottom right, #EB5B49, #F8A59A);
  border-radius: 9px;
}

.rezi-container {
  background-image: linear-gradient(to bottom right, #3E1BA8, #230778);
  border-radius: 9px;
}

.peerlogix-container {
  background-image: linear-gradient(to bottom right, rgba(61, 155, 233), #71C538);
  border-radius: 9px;
}

.body-copy-white, .header-2-white, .header-1-white, .body-copy-small-white {
  font-family: 'Montserrat', sans-serif;
}

.header-2-black {
  color: #2E2E2E;
}


.body-copy-black, .header-2-black, .header-1-black, .body-copy-small-black, .body-copy-bold-black {
  color: #2E2E2E;
}

.body-copy-white, .header-2-white, .header-1-white, .body-copy-small-white, .body-copy-bold {
  color: #FFFFFF;
}

.header-1-white, .header-1-black {
  font-size: 36px;
}

.body-copy-white, .body-copy-bold, .body-copy-bold-black, .body-copy-black {
  font-size: 22px;
}

.body-copy-small-white, .body-copy-small-black {
  font-size: 18px;
}

.body-copy-white, .body-copy-small-white, .body-copy-black, .body-copy-small-black {
  font-weight: 500;
}

.header-2-black, .header-2-white, .body-copy-bold, .body-copy-bold-black {
  font-weight: 600;
}

.header-2-black, .header-2-white {
  font-size: 28px;
}

.restage-logo-img,
.peerlogix-logo-img,
.somewheregood-logo-img,
.formnoform-logo-img,
.rezi-logo-img  {
  margin-bottom: 15px;
  max-width: 100%;
}

.restage-logo-img {
  max-height: 60px;
  object-fit: contain;
}

.peerlogix-logo-img {
  max-height: 60px;
  width: 100%;
  object-fit: contain;
}

.rezi-logo-img {
  max-height: 150px;
  object-fit: contain;
}


.formnoform-logo-img {
  max-height: 50px;
  object-fit: contain;
}

.somewheregood-logo-img {
  max-height: 200px;
  object-fit: contain;
}

.link-color {
  color: #FFFFFF;
  text-decoration: underline;
}

.link-color:hover {
    color: #FFFFFF;
}

.link-color-black {
  color: #2E2E2E;
  text-decoration: underline;
}

.link-color-black:hover {
    color: #2E2E2E;
}

.segment-outer-bound {
  padding: 15px;
}
