@media (min-width: 768px) {
  .work-header {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 50px;
  }
}

@media (max-width: 767px) {
  .work-header {
    padding-top: 50px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
